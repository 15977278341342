$(document).foundation();

$(function() {


    // After scrolling 200px down, add class to header
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('#header.transparent').addClass('sticky');
        } else {
            $('#header.transparent').removeClass('sticky');
        }
    });


    if($('.hero-carousel').length > 0) {
        $('.hero-carousel').not('.slick-initialized').slick({
            dots: true,
            infinite: true,
            autoplay: true,
            fade: true,
            autoplaySpeed:7000,
            pauseOnHover: false,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M4.7 244.7c-6.2 6.2-6.2 16.4 0 22.6l176 176c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 272 432 272c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 240 203.3 91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-176 176z"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg></button>',
        });    
    }

    if($('.featured-event-slider').length > 0) {
        $('.featured-event-slider').not('.slick-initialized').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            fade: true,
            autoplaySpeed:7000,
            pauseOnHover: false,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M4.7 244.7c-6.2 6.2-6.2 16.4 0 22.6l176 176c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 272 432 272c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 240 203.3 91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-176 176z"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg></button>',
        });    
    }

    if($('.grid-slider').length > 0) {
        $('.grid-slider').not('.slick-initialized').slick({
            dots: false,
            infinite: false,
            arrows: false,
            autoplay: false,
            slidesToShow: 1.5,
            mobileFirst: true,
            responsive: [
                {
                breakpoint: 640,
                settings: "unslick"
                }
            ]
        });
    }

    $('.book-feed').each((index, el) => {
        $(el).not('.slick-initialized').slick({
            dots: false,
            infinite: false,
            autoplay: false,
            slidesToShow: 6,
            slidesToScroll: 6,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M4.7 244.7c-6.2 6.2-6.2 16.4 0 22.6l176 176c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 272 432 272c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 240 203.3 91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-176 176z"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z"/></svg></button>',
            appendArrows: $(el).parents('.elements__bookfeed').find('.slick-arrows'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 3,
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      arrows: false
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 2.5,
                      slidesToScroll: 3,
                    }
                  }
            ]
        });
    });

    $(document).on('click', '#btn-mobile-menu', function() {
        $('.mobile-nav-wrap').toggleClass('open');
        $('body').toggleClass('no-scroll');
    });

    $(document).on('click', '.l1-link-mobile.parent:not(.open)', function(e) {
        $(this).addClass('open');
        $(this).parent().addClass('tray-open');
        $(this).parent().find('.mobile-nav-tray').slideDown();
        $('.l1-item').not($(this).parent()).hide();
        e.preventDefault();
        return false;
    });

    $(document).on('click', '.btn-close-parent-link', function(e) {
        $(this).parents('.l1-item').removeClass('tray-open');
        $(this).parent().find('.l1-link-mobile').removeClass('open');
        $(this).parent().find('.mobile-nav-tray').slideUp();
        $('.l1-item').show();
        e.preventDefault();
        return false;
    });

    $(document).on('change', '#mobile-subnav-select', function() {
        window.location.href = $(this).val();
    });

    $(document).on('click', '.btn-toggle-section', function() {
        $(this).toggleClass('open');
        $(this).parents('.accordion-section').find('.accordion-item').toggleClass('open');
        $(this).parents('.database-section').find('.database-list').toggleClass('open');
    });

    $(document).on('click', '.btn-database-section', function(e) {
        let anchor = $(this).data('target');
        let target = $(anchor);

        $([document.documentElement, document.body]).animate({
            scrollTop: target.offset().top - 100
        }, 1000);

        $(this).toggleClass('open');
        $(this).addClass('active').siblings().removeClass('active');

        target.find('.btn-toggle-section').addClass('open');
        target.find('.database-list').addClass('open');

        e.preventDefault();
        return false;
          
    });

    $(document).on('click', '.question-tile', function() {
        $(this).toggleClass('open');
    });

    $(document).on('click', '#btn-open-search', function() {
        $('.search-wrap').addClass('open');
        $('#header.transparent').addClass('solid');
        $('#search-input').focus();
    });
    $(document).on('click', '#btn-close-search', function() {
        $('.search-wrap').removeClass('open');
        $('#header.transparent').removeClass('solid');
    });

    $(document).on('submit', '#search-form', function(e) {
        let searchValue = $('#search-input').val();
        let searchSource = $('#search-dropdown').val();
        $('.search-dropdown').toggleClass('open');
        if (searchSource === 'catalog') {
            window.open('https://bpl.tlcdelivers.com/#section=search&term='+searchValue, '_blank');
            e.preventDefault();
            return false;
        }
    });

    $(document).on('click', '#btn-mobile-search', function() {
        $('#mobile-search').toggleClass('open');
        $('#mobile-search').find('.search-input').focus();
    });

    $(document).on('click', '#btn-close-mobile-search', function() {
        $('#mobile-search').toggleClass('open');
    });

    $(document).on('click', '.search-switch', function() {
        $(this).toggleClass('on');
    });

    $(document).on('submit', '#mobile-search-form', function(e) {
        let searchValue = $(this).find('.search-input').val();
        if (!$('.search-switch').hasClass('on')) {
            window.open('https://bpl.tlcdelivers.com/#section=search&term='+searchValue, '_blank');
            e.preventDefault();
            return false;
        }
    });

    // $(document).on('click', '#search-dropdown', function() {
    //     $('#search-form')
    // });

    $(document).on('submit', '#newsletter-form', function() {
        let form = $(this);
        let submitMessage = form.parent().find('.success-message');
        form.addClass('loading disabled');
    
        $(this).ajaxSubmit({
            success: function(){
                form.removeClass('loading disabled').hide();
                submitMessage.show();
            },
            clearForm:true
        });
    
        return false;
    });

    setTimeout(() => {
        $('#email_address_0').attr('placeholder', 'Enter your email address');
        $('.ctct-form-checkbox', '#ctct_form_0').attr('checked', true);
        $('#email_list_0_0').next('label').html('Adults');
        $('#email_list_1_0').next('label').html('Kids');
        $('#email_list_2_0').next('label').html('Teens');
    }, 1000);
    

    $(document).on('mouseover', '#header.transparent .desktop-nav .l1-item.parent', function() {
        $('#header').addClass('solid');
        $('.search-wrap').removeClass('open');
    }).on('mouseleave', '#header.transparent .l1-item', function() {
        setTimeout(() => {
            $('#header').removeClass('solid');
        }, 500);
    });

    
    $('#newsletter-form').on('submit', function() {
        let checked = $(this).find("input[type=checkbox]:checked").length;

        if(!checked) {
            $(this).find('.submit-message').html("Please choose at least one featured newsletter.");
            return false;
        }
    });

    if (typeof recaptchascript == 'undefined') {
        Array.from(document.querySelectorAll("form")).forEach((form, index) => {
            let input = form.querySelector(`input[name="CaptchaToken"]`);
        
            if (input) {
                let captcha = document.createElement("div");
                captcha.className = "g-recaptcha";
                captcha.dataset.callback = "captchaCallback_" + index;
                captcha.dataset.sitekey = input.dataset.key;
        
                input.after(captcha);
        
                globalThis[`captchaCallback_${index}`] = token => input.value = token;
            }
        });
        
        let recaptchascript = document.createElement("script");
        recaptchascript.src = "https://www.google.com/recaptcha/api.js";
        document.body.appendChild(recaptchascript);
    }

});
